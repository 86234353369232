import React from "react"
import { css } from "emotion"
import { Redirect } from "react-router-dom"
import BallPit from "../components/ball-pit"
import colors, { colorRotation } from "../colors"
import { StateConsumer } from "../state"

class Login extends React.Component {
  state = {
    password: "",
    incorrectPassword: false,
    obstacles: [],
    ballPitReady: false,
    colorIndex: 0
  }

  handleChange = (event, key) => {
    const {
      target: { value }
    } = event
    this.setState({ [key]: value })
  }

  changeColor = () => {
    this.setState({ colorIndex: this.state.colorIndex + 1 })
  }

  componentDidMount = () => {
    setTimeout(
      () =>
        this.setState({ colorIndex: this.state.colorIndex + 1 }, () => {
          this.interval = setInterval(this.changeColor, 2000)
        }),
      300
    )
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  onUnlockButtonRender = elem => {
    if (elem) {
      setTimeout(() => {
        const { x, y, width, height } = elem.getBoundingClientRect()
        this.setState({
          obstacles: [
            {
              type: "rectangle",
              x: x + width / 2,
              y: y + height / 2,
              height,
              width,
              config: {
                chamfer: { radius: 4, quality: 10 }
              }
            }
          ],
          ballPitReady: true
        })
      }, 800)
    }
  }

  handleSubmit = async (event, logIn) => {
    const { password } = this.state
    event.preventDefault()
    if (password === "") {
      this.input.focus()
    } else {
      try {
        await logIn(password)
      } catch (error) {
        this.setState({
          incorrectPassword: true
        })
      }
    }
  }

  render() {
    const {
      password,
      colorIndex,
      incorrectPassword,
      ballPitReady,
      obstacles
    } = this.state
    const color = colorRotation[colorIndex % colorRotation.length]
    return (
      <StateConsumer>
        {({ loggedIn, logIn }) =>
          loggedIn === undefined ? null : loggedIn === false ? (
            <React.Fragment>
              <form
                action="api/login"
                method="post"
                onSubmit={event => this.handleSubmit(event, logIn)}
                className={pageStyle}
              >
                <h1 className={headingStyle}>
                  Just between{" "}
                  <span className="dont-break">
                    <span className="you">you</span> and{" "}
                    <span className="me">me</span>
                  </span>
                </h1>
                <input
                  ref={input => (this.input = input)}
                  className={inputStyle(color)}
                  value={password}
                  type="password"
                  name="password"
                  placeholder="•••••"
                  onChange={event => this.handleChange(event, "password")}
                />
                {incorrectPassword ? (
                  <p className={incorrectPasswordStyle}>
                    <span className="highlight">Pobrecito.</span> That's the
                    wrong passsword.
                  </p>
                ) : (
                  <div className={css({ height: 34 })} />
                )}
                <button
                  ref={this.onUnlockButtonRender}
                  className={buttonStyle(color)}
                >
                  unlock
                </button>
              </form>
              {ballPitReady && <BallPit obstacles={obstacles} />}
            </React.Fragment>
          ) : (
            <Redirect
              to={{
                pathname: "/questions"
              }}
            />
          )
        }
      </StateConsumer>
    )
  }
}

const pageStyle = css({
  margin: "auto",
  maxWidth: 800,
  padding: `160px 60px 0`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  boxSizing: "border-box",
  justifyContent: "center"
})

const headingStyle = css({
  fontSize: 20,
  fontWeight: `700`,
  textAlign: "center",
  margin: 0,
  "& span.dont-break": {
    display: "inline-block"
  },
  "& span.me": {
    color: colors.me
  },
  "& span.you": {
    color: colors.you
  }
})

const TIMING = "2000ms"

const buttonStyle = color =>
  css({
    fontWeight: 700,
    background: color,
    transition: `background ${TIMING}`,
    border: "none",
    outline: "none",
    borderRadius: 4,
    marginTop: 30,
    fontSize: 20,
    width: 150,
    padding: "20px 40px"
  })

const incorrectPasswordStyle = css({
  margin: 0,
  fontSize: 12,
  padding: 8,
  textShadow: `0 0 2px ${colors.background}`,
  zIndex: 10,
  textAlign: "center",
  lineHeight: 1.5,
  "span.highlight": {
    fontWeight: 700,
    color: colors.error
  }
})

const inputStyle = color =>
  css({
    zIndex: 10,
    color: color,
    transition: `color ${TIMING}`,
    fontSize: 40,
    padding: `30px 0`,
    fontWeight: 700,
    background: colors.accent,
    border: "none",
    outline: "none",
    marginTop: 30,
    width: "100%",
    textAlign: "center",
    letterSpacing: 20,
    height: 50,
    "&::placeholder": {
      transition: `color ${TIMING}`,
      transform: "translateX(10px)",
      opacity: 0.4,
      color
    }
  })

export default Login
