import React, { Component } from "react"
import { injectGlobal } from "emotion"
import colors from "./colors"
import State from "./state"
import Router from "./router"

injectGlobal`
  @import url("https://fonts.googleapis.com/css?family=Open+Sans:400|PT+Serif:400,700");
  * {
    font-family: "PT Serif", serif;
  }
  body {
    background-color: ${colors.background};
    color: ${colors.text};
  }
`

class App extends Component {
  render() {
    return (
      <State>
        <Router />
      </State>
    )
  }
}

export default App
