import React from "react"
import { css } from "emotion"
import random from "lodash/random"
import { Engine, Render, World, Bodies } from "matter-js"
import colors, { colorRotation } from "../colors"

const scale = window.devicePixelRatio
const pX = percent => Math.round((percent / 100) * window.innerWidth * scale)
const pY = percent => Math.round((percent / 100) * window.innerHeight * scale)
const WIDTH = window.innerWidth
const HEIGHT = window.innerHeight

const randomX = () => pX(random(0, 100))

const newRectangle = (...args) => Bodies.rectangle(...args)
const newBall = (x, y, color, config = {}) =>
  Bodies.circle(x, y, 15 * scale, {
    restitution: 0.5,
    render: {
      fillStyle: color
    },
    ...config
  })

const boundryConfig = {
  isStatic: true,
  render: {
    fillStyle: colors.background
  }
}

const GROUND = Bodies.rectangle(
  pX(50),
  pY(100) + 10,
  pX(100),
  20,
  boundryConfig
)

const WALLS = [
  newRectangle(pX(0) - 10, pY(50), 20, pY(200), boundryConfig),
  newRectangle(pX(100) + 10, pY(50), 20, pY(200), boundryConfig)
]

const createBalls = number =>
  Array(number)
    .fill(null)
    .map((ball, index) =>
      newBall(
        randomX(),
        -100 - index * 50,
        colorRotation[index % colorRotation.length]
      )
    )

const makeObstacles = obstacles =>
  obstacles.map(o => {
    return o.type === "rectangle"
      ? newRectangle(
          o.x * scale,
          o.y * scale,
          o.width * scale,
          o.height * scale,
          {
            isStatic: true,
            render: {
              fillStyle: "transparent"
            },
            ...o.config
          }
        )
      : newBall(o.x * scale, o.y * scale, "transparent", {
          isStatic: true
        })
  })

class BallPit extends React.Component {
  componentDidMount() {
    const canvas = document.getElementById("ball-pit")
    const context = canvas.getContext("2d")
    context.scale(2, 2)
    const { numberBalls = 50, obstacles = [] } = this.props
    this.engine = Engine.create()
    this.engine.world.gravity.scale *= scale
    this.matterRender = Render.create({
      canvas,
      engine: this.engine,
      options: {
        width: WIDTH * scale,
        height: HEIGHT * scale,
        background: "transparent",
        wireframes: false,
        showAngleIndicator: false
      }
    })
    const obs = makeObstacles(obstacles).filter(Boolean)
    const lastMinuteBall = Bodies.circle(pX(50), -10000, 30 * scale, {
      restitution: 0.5,
      render: {
        fillStyle: colorRotation[random(0, colorRotation.length)]
      }
    })
    const balls = [...createBalls(numberBalls), lastMinuteBall]
    World.add(this.engine.world, [...balls, GROUND, ...WALLS, ...obs])
    Engine.run(this.engine)
    Render.run(this.matterRender)
  }

  render() {
    return <canvas id="ball-pit" className={ballPitStyle} />
  }
}

const ballPitStyle = css({
  position: "absolute",
  width: WIDTH,
  height: HEIGHT,
  top: 0,
  left: 0,
  pointerEvents: "none"
})

export default BallPit
