import axios from "axios"

const baseURL =
  process.env.NODE_ENV === "production"
    ? "/api"
    : "http://192.168.50.103:8080/api"

const a = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": baseURL
  }
})

a.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error.response.data)
)

const isAuthenticated = () => a.get("isAuthenticated")

const logIn = password =>
  a.post("login", {
    password
  })

const logOut = () => a.get("logout")

const getAnswers = () => a.get("answers")

const changeAnswer = (question, answer) =>
  a.post("answer", { data: { question, answer } })

const getLastUpdated = () => a.get("lastUpdated")

const updateLastUpdated = () =>
  a.post("lastUpdated", { data: { date: Date.now() } })

export {
  isAuthenticated,
  logIn,
  logOut,
  getAnswers,
  changeAnswer,
  getLastUpdated,
  updateLastUpdated
}
