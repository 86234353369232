import React from "react"

const options = {
  weekday: "short",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit"
}

const makeDateString = date => {
  try {
    const dateString = new Date(Date.parse(date)).toLocaleDateString(
      "en-Us",
      options
    )
    if (dateString === "Invalid Date") throw dateString
    return dateString
  } catch (error) {
    return "Sat, April 6, 1:05 PM"
  }
}

const LastUpdated = ({ children, date, ...props }) => {
  const dateString = makeDateString(date)
  return <p {...props}>last updated {date ? dateString : "..."}</p>
}

export default LastUpdated
