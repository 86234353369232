import React from "react"
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom"
import { StateConsumer } from "./state"
import Login from "./screens/login"
import Questions from "./screens/questions"

const Router = () => (
  <BrowserRouter>
    <Switch>
      <PublicRoute exact path="/" component={Login} />
      <PrivateRoute path="/questions" component={Questions} />
      <PublicRoute component={NoMatch} />
    </Switch>
  </BrowserRouter>
)

const PublicRoute = ({ component: Component, otherProps, ...rest }) => (
  <Route {...rest} render={props => <Component {...props} {...otherProps} />} />
)

const PrivateRoute = ({ component: Component, ...rest }) => (
  <StateConsumer>
    {({ loggedIn }) => (
      <Route
        {...rest}
        render={props =>
          loggedIn === undefined ? null : loggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    )}
  </StateConsumer>
)

const NoMatch = () => (
  <Redirect
    to={{
      pathname: "/"
    }}
  />
)

export default Router
