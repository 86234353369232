const colorRotation = ["#F0206C", "#FFBC42", "#0696FF", "#06FF57", "#F97E35"]

const colors = {
  background: "#18181D",
  text: "#F7EDDC",
  accent: "#3A3A57",
  error: colorRotation[0],
  you: colorRotation[1],
  me: colorRotation[2]
}

export default colors
export { colorRotation }
