import React from "react"
import { css, keyframes } from "emotion"
import colors from "../colors"

const Option = ({ children, color, selected, handleRef, onClick }) => (
  <button
    className={optionStyle(color, selected)}
    ref={handleRef}
    onClick={onClick}
  >
    {children}
  </button>
)

class Question extends React.Component {
  render() {
    const {
      children,
      answer,
      changeAnswer,
      color,
      handleRef,
      index,
      ...props
    } = this.props
    return (
      <div {...props} className={questionStyle(color, index)}>
        <span>
          Are you <span className="highlight">{children}</span>?
        </span>
        <div>
          <Option
            color={color}
            onClick={() => changeAnswer(true)}
            selected={answer}
            handleRef={answer ? elem => handleRef(elem) : null}
          >
            <span>yes</span>
          </Option>
          <Option
            color={color}
            handleRef={
              !answer && answer !== undefined ? elem => handleRef(elem) : null
            }
            onClick={() => changeAnswer(false)}
            selected={!answer && answer !== undefined}
          >
            <span>no</span>
          </Option>
        </div>
      </div>
    )
  }
}

const RADIUS = 35
const TIMING = "200ms"

const transitionIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const optionStyle = (color, selected) =>
  css({
    background: selected ? color : "none",
    transition: `background ${TIMING}`,
    border: "none",
    fontSize: 18,
    fontWeight: `700`,
    outline: "none",
    borderRadius: "50%",
    height: RADIUS,
    width: RADIUS,
    marginLeft: 22,
    padding: 0,
    position: "relative",
    "& span": {
      color: selected ? colors.background : colors.text,
      transition: `color ${TIMING}`,
      position: "absolute",
      top: 6,
      left: 0,
      right: 0
    }
  })

const questionStyle = (color, index) =>
  css({
    display: "flex",
    opacity: 0,
    animation: `${transitionIn} ${TIMING} ${index * 100}ms ease`,
    animationFillMode: "forwards",
    flexDirection: "row",
    alignItems: "center",
    margin: `18px 0`,
    justifyContent: "space-between",
    fontSize: 18,
    "span.highlight": {
      fontWeight: `700`,
      color
    }
  })

export default Question
