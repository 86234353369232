import React, { createContext, Component } from "react"
import { isAuthenticated, logIn, logOut } from "./api"
const { Provider: StateProvider, Consumer: StateConsumer } = createContext()

const QUESTIONS = [
  "hungry",
  "tired",
  "excited",
  "thirsty",
  "confused",
  "happy",
  "sad",
  "nervous"
]

class State extends Component {
  setStatePromise = newState =>
    new Promise(resolve => {
      this.setState(newState, () => {
        resolve()
      })
    })

  state = {
    questions: QUESTIONS,
    loggedIn: undefined,
    setLoggedIn: loggedIn => {
      return this.setStatePromise({ loggedIn })
    },
    logIn: (username, password) => {
      return this.tryToLogIn(username, password)
    },
    logOut: async () => {
      await this.state.setLoggedIn(false)
      await logOut()
    }
  }

  tryToLogIn = async (username, password) => {
    try {
      await logIn(username, password)
      this.state.setLoggedIn(true)
    } catch (error) {
      this.state.setLoggedIn(false)
      return Promise.reject(error)
    }
  }

  componentDidMount = async () => {
    try {
      await isAuthenticated()
      this.state.setLoggedIn(true)
    } catch (error) {
      this.state.setLoggedIn(false)
    }
  }

  render = () => (
    <StateProvider value={this.state}>{this.props.children}</StateProvider>
  )
}

export default State
export { StateConsumer }
