import React from "react"
import { css } from "emotion"
import Question from "../components/question"
import LastUpdated from "../components/last-updated"
import BallPit from "../components/ball-pit"
import {
  getAnswers,
  changeAnswer,
  getLastUpdated,
  updateLastUpdated
} from "../api"
import { StateConsumer } from "../state"
import colors, { colorRotation } from "../colors"

class Questions extends React.Component {
  state = {
    answers: [],
    lastUpdated: undefined,
    ballPitReady: false,
    ready: false,
    obstacles: []
  }

  obstacles = []

  componentDidMount = async () => {
    const answers = await getAnswers()
    const { date: lastUpdated } = await getLastUpdated()
    this.setState({ answers, lastUpdated, ready: true })
  }

  handleChange = (event, key) => {
    const {
      target: { value }
    } = event
    this.setState({ [key]: value })
  }

  handleChangeAnswer = async (question, answer) => {
    try {
      await Promise.all([changeAnswer(question, answer), updateLastUpdated()])
      const answers = await getAnswers()
      const { date: lastUpdated } = await getLastUpdated()
      this.setState({ answers, lastUpdated })
    } catch (error) {
      console.log("error", error)
    }
  }

  onQuestionRef = (elem, numQuestions) => {
    if (elem && this.obstacles.length < numQuestions) {
      const { x, y, width, height } = elem.getBoundingClientRect()
      const newObstacle = {
        type: "circle",
        x: x + width / 2,
        y: y + height / 2,
        height,
        width
      }
      this.obstacles.push(newObstacle)
      if (this.obstacles.length === numQuestions) {
        this.setState({ obstacles: this.obstacles, ballPitReady: true })
      }
    }
  }

  render() {
    const { answers, lastUpdated, ballPitReady, obstacles, ready } = this.state
    return (
      <StateConsumer>
        {({ state, logOut, questions }) => {
          return (
            <div className={pageStyle}>
              <h1 className={headingStyle}>
                Just between <span className="you">you</span> and{" "}
                <span className="me">me</span>
              </h1>
              <div className={headingRowStyle}>
                <LastUpdated className={lastUpdatedStyle} date={lastUpdated} />
                <button className={logOutStyle} onClick={logOut}>
                  Lock
                </button>
              </div>
              <div className={dividerStyle} />
              {ready &&
                questions.map((question, index) => {
                  const { answer } =
                    answers.find(({ question: q }) => q === question) || {}
                  const color = colorRotation[index % colorRotation.length]
                  return (
                    <Question
                      index={index}
                      color={color}
                      key={question}
                      handleRef={elem =>
                        this.onQuestionRef(elem, questions.length)
                      }
                      changeAnswer={answer =>
                        this.handleChangeAnswer(question, answer)
                      }
                      answer={answer}
                    >
                      {question}
                    </Question>
                  )
                })}
              {ballPitReady && (
                <BallPit numberBalls={30} obstacles={obstacles} />
              )}
            </div>
          )
        }}
      </StateConsumer>
    )
  }
}

const headingStyle = css({
  fontSize: 22,
  fontWeight: `700`,
  lineHeight: 1.5,
  margin: 0,
  "& span.me": {
    color: colors.me
  },
  "& span.you": {
    color: colors.you
  }
})

const pageStyle = css({
  padding: `60px 28px`,
  margin: "auto",
  boxSizing: "border-box",
  position: "relative",
  maxWidth: 800
})

const dividerStyle = css({
  height: 5,
  width: "100%",
  marginTop: 10,
  marginBottom: 20,
  background: colors.accent
})

const lastUpdatedStyle = css({
  margin: 0,
  fontSize: 10,
  fontFamily: `"Open Sans", sans-serif`
})

const headingRowStyle = css({
  marginTop: 2,
  marginBottom: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
})

const logOutStyle = css({
  padding: 0,
  margin: 0,
  fontWeight: 700,
  background: "none",
  border: "none",
  outline: "none",
  color: colors.accent,
  textDecoration: "underline",
  fontSize: 10
})

export default Questions
